// Styles
@import "./scss/style.scss";
@import "~@fortawesome/fontawesome-free/css/all.css";

//tables
.rdt_TableCell {
  font-size: 15px !important;
}
#search {
  max-width: 200px;
}
.tableCards {
  max-width: 40px;
  max-height: 24px;
}
.rdt_TableRow {
  border-bottom: 1px solid #66615f;
  border-top: 1px solid #66615f;
}
.dtaFNg {
  color: #d39b2d !important;
}
.mainLogo {
  max-width: 1000px;
  max-height: 1000px;
}
.loginLogo {
  max-width: 500px;
  max-height: 500px;
}
.app-footer {
  border-top: 1px solid #454241;
}
.app-header {
  border-bottom: 1px solid #454241;
}
//sidebar
.sidebar .nav-link:hover {
  color: #d39b2d !important;
  background: #454241;
}
.sidebar .nav-item:hover {
  color: #d39b2d;
  background: #454241;
}
.sidebar .nav-dropdown.open {
  color: #f0f0f0;
  background: #3a3737;
}
.sidebar .nav-dropdown.open .nav-link {
  background: #3a3737;
}
.sidebar .nav-link.active {
  color: #f0f0f0;
  background: #454241;
}
.sidebar .nav-link:hover .nav-icon {
  color: #d39b2d;
}
.sidebar .nav-link.active .nav-icon {
  color: #f0f0f0;
}
.sidebar .nav-link .nav-icon {
  color: #f0f0f0;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    color: #d39b2d;
    background: #454241;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    color: #f0f0f0;
    background: #454241;
  }
  .sidebar-minimized .sidebar .sidebar-minimizer {
    color: #f0f0f0;
    background: #454241;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    color: #d39b2d;
    background: #454241;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link nav-icon {
    color: #d39b2d;
    background: #454241;
  }
  .sidebar-minimized .sidebar .nav-link:hover {
    color: #d39b2d;
    background: #454241;
  }
}
//navbar
.navbar-nav .nav-link {
  color: #f0f0f0;
}
.navbar-nav .nav-link:hover {
  color: #d39b2d;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #d39b2d;
}
:focus {
  outline-color: #3f3c3b;
}
button:focus {
  outline-color: #3f3c3b;
}
.btn-secondary:hover {
  color: #23282c;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #f0f0f0;
  background: #3f3c3b;
}
.dropdown-item i {
  color: #f0f0f0;
}
//dataTable
.ksKbuS {
  color: #f0f0f0 !important;
}
.hLrWNh {
  color: #f0f0f0 !important;
}
.JzHiH {
  color: #f0f0f0 !important;
}
.iHDHnc {
  color: #f0f0f0 !important;
}
.inhAeF {
  color: #f0f0f0 !important;
}
.btn-link:hover {
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
a:hover {
  color: #d39b2d;
}
.classLogo {
  width: 150px;
  height: 100px;
}
//pagination
.rdt_TableFooter {
  .dQKnoF {
    background-color: #f0f0f0;
  }
  .fJRgfo {
    color: #f0f0f0 !important;
  }
  .hNRBLd {
    background-color: #f0f0f0;
  }
  .hElmdu {
    color: #f0f0f0 !important;
  }
  .dQKnoF:hover:not(:disabled) {
    background-color: #3f3c3b;
  }
}
//modals
.modal-content {
  color: #f0f0f0;
  background: #3f3c3b;
}
//forms
.form-control {
  // color: #f0f0f0;
  // background: #3f3c3b;
  border: 1px solid hsl(20, 4%, 30%);
}
.form-control:focus {
  // color: #f0f0f0;
  // background: #3f3c3b;
  border: 1px solid hsl(20, 4%, 30%);
}
.input-group-text {
  border: 1px solid hsl(20, 4%, 30%);
}
//list-group
.list-group-item {
  border: 1px solid hsl(20, 4%, 30%);
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  color: #f0f0f0;
  background: #3f3c3b;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  color: #f0f0f0;
  background: #3f3c3b;
}

.rec-cards {
  font-size: 20px;
  font-weight: 400;
}
//stripe
.example {
  .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  }

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 15px;
    letter-spacing: 0.025em;
    background-color: #f0f0f0;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }

  form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
  }

  button:hover {
    cursor: pointer;
    background-color: #dddddd;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
}
.loginCard {
  height: 400px;
}

.terms {
  width: 700px;

  .modal-body {
    overflow: auto;
    max-height: 500px;
  }
}

.class-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.class-title {
  margin-left: 100px;
}

.class-button {
  margin-right: 100px;
}
